.dashboard h1 {
  /* margin-top: 30px; */
  text-align: center;
  padding: 30px;
  /* background-color: #e74c3c; */
  /* color: #ffffff; */
}

.dashboard .container {
  /* max-width: 700px; */
  margin-top: 30px;
}

.dashboard .card {
  text-align: center;
}

.dashboard .card-title {
  text-align: center;
  font-weight: normal;
}

.score {
  text-align: center;
  font-size: 70px;
}